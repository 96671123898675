<template>
    <div>
        <breadcrumb
            name="Service Appointment"
            title="Requests"
            subtitle="View"
            @onTitleClicked="viewRequests"
        />

        <vx-card title="User Info" class="mb-base">
            <vs-divider />
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table" v-if="item">
                            <tr>
                                <td class="font-semibold p-2">User Name</td>
                                <td class="p-2">{{ item.user_name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">User Mobile</td>
                                <td class="p-2">{{ item.user_mobile }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">User Email</td>
                                <td class="p-2">{{ item.user_email }}</td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card title="Request Info." class="mb-base">
            <vs-divider />
            <vs-row vs-type="flex" class="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <h4 class="header">User's Car Details</h4>
                        <vs-row>
                            <vs-col vs-lg="4">
                                <vs-divider />
                            </vs-col>
                        </vs-row>
                        <table class="sm-table" v-if="item">
                            <tr>
                                <td class="font-semibold p-2">VIN</td>
                                <td class="p-2">
                                    {{
                                        item.user_car
                                            ? item.user_car.vin_number ||
                                              "Not Set"
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Car Brand</td>
                                <td class="p-2">
                                    {{
                                        item.user_car
                                            ? item.user_car.brand_en ||
                                              "Not Set"
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Model Year</td>
                                <td class="p-2">
                                    {{
                                        item.user_car
                                            ? item.user_car.year || "Not Set"
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Chassis No.</td>
                                <td class="p-2">
                                    {{
                                        item.user_car
                                            ? item.user_car.chassis_no ||
                                              "Not Set"
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Current KMs</td>
                                <td class="p-2">
                                    {{
                                        item.user_car
                                            ? item.user_car.kilometers +
                                                  " KM" || "Not Set"
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">Plate No.</td>
                                <td class="p-2">
                                    {{
                                        item.user_car
                                            ? item.user_car.license || "Not Set"
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <h4 class="header">Request Details</h4>
                        <vs-row>
                            <vs-col vs-lg="4">
                                <vs-divider />
                            </vs-col>
                        </vs-row>
                        <table class="sm-table" v-if="item">
                            <tr>
                                <td class="font-semibold p-2">Date / Time</td>
                                <td class="p-2">
                                    {{
                                        item.created_at | moment("DD MMMM YYYY")
                                    }}
                                    / {{ item.created_at | moment("h:mm A") }}
                                </td>
                            </tr>
                            <!--              <tr v-if="item.cancelation_reason">-->
                            <!--                <td class="font-semibold p-2">Cancellation Reason</td>-->
                            <!--                <td class="p-2">{{item.cancelation_reason || "Undefined"}}-->
                            <!--                </td>-->
                            <!--              </tr>-->
                            <tr>
                                <td class="font-semibold p-2">
                                    Service Center Branch
                                </td>
                                <td class="p-2">
                                    {{ item.service_center || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">
                                    Service Scheduled
                                </td>
                                <td class="p-2">
                                    {{ item.service || "Undefined" }}
                                </td>
                            </tr>

                            <tr>
                                <td class="font-semibold p-2">Inserted KMs</td>
                                <td class="p-2">
                                    {{ item.km || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">
                                    Additional Comments
                                </td>
                                <td class="p-2">
                                    {{
                                        item.additional_comments || "Undefined"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-2">
                                    Appointment Date
                                </td>
                                <td class="p-2">
                                    {{ item.date || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold pt-2 pl-2">
                                    Appointment Time
                                </td>
                                <td class="pt-2 pl-2">
                                    {{ item.hour || "Undefined" }}
                                </td>
                            </tr>
                            <!-- <tr>
                                <td class="font-semibold pt-2 pl-2">
                                    Agent Status
                                </td>
                                <td class="pt-2 pl-2">
                                    <vs-chip
                                        :color="
                                            getAgentStatusColor(
                                                item.agent_status
                                                    ? item.agent_status
                                                    : 'None'
                                            )
                                        "
                                        class="product-order-status text-center"
                                    >
                                        {{
                                            getAgentStatusState(
                                                item.agent_status
                                                    ? item.agent_status
                                                    : "None"
                                            )
                                        }}
                                    </vs-chip>
                                </td>
                            </tr> -->

                            <tr v-if="item.agent_comment">
                                <td class="font-semibold pl-2 pt-2">
                                    Agent Comment
                                </td>
                                <td v-html="item.agent_comment"></td>
                            </tr>
                            <!-- <tr>
                                <td class="font-semibold pl-2 pt-2">
                                    Agent Email
                                </td>
                                <td style="">
                                    <span
                                        v-if="
                                            item.agent_status &&
                                                item.agent_status.admin
                                        "
                                    >
                                        {{ item.agent_status.admin.email }}
                                    </span>
                                    <span v-else>None</span>
                                </td>
                            </tr> -->
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card title="Update Status" class="mb-base">
            <vs-divider />
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold p-2">
                                    Request Status
                                </td>
                                <td class="p-2">
                                    <vs-chip
                                        :color="
                                            getOrderStatusColor(
                                                item.status,
                                                item.agent_status
                                            )
                                        "
                                        class="product-order-status"
                                    >
                                        {{
                                            getOrderStatusState(
                                                item.status,
                                                item.agent_status
                                            )
                                        }}
                                    </vs-chip>
                                    <feather-icon
                                        icon="EditIcon"
                                        svgClasses="w-6 mt-1 h-6 mr-2 hover:text-warning stroke-current"
                                        @click="status = !status"
                                        class="ml-12"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col vs-lg="4" v-if="status">
                    <label for="actions">New Status</label>
                    <select id="actions" v-model="new_status">
                        <option value="-1">Cancelled</option>
                        <option value="0">Pending</option>
                        <option value="4">In Progress</option>
                        <option value="1">Accepted</option>
                        <option value="2">Scheduled</option>
                        <option value="5">Not Reachable</option>
                    </select>
                </vs-col>
                <vs-col vs-lg="4" v-if="status && new_status == 2">
                    <label class="input">New Date</label>
                    <vs-input type="date" v-model="date" class="input" />
                </vs-col>
                <vs-col vs-lg="4" v-if="status && new_status == 2">
                    <label class="input">New Time</label>
                    <vs-input type="time" v-model="time" class="input" />
                </vs-col>
                 <div class="english" v-if="status">
                    <small class="label">Agent Comment</small>
                    <ckeditor
                        :editor="editor"
                        v-model="agent_comment"
                        :config="editorConfigEn"
                        class="input"
                        v-if="!showHTML"
                    />
                    <textarea v-model="agent_comment" v-if="showHTML"></textarea>
                </div>
            </vs-row>
            <div class="optionBtns" v-if="status">
                <vs-button
                    :disabled="invalidForm"
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="confirmSaveData"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="status = false"
                    >Cancel
                </vs-button>
            </div>
        </vx-card>
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";
import TagRow from "../../../components/table/rows/tag-row";
import AgentStatusRow from "../../../components/table/rows/agent-status-row";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "view-booking-request",
    components: { TagRow, Breadcrumb, AgentStatusRow },
    data() {
        return {
            status: false,
            new_status:
                this.item.status == 0 &&
                this.item.agent_status === "in_progress"
                    ? 4
                    : this.item.status,
            date: this.item.status == 2 ? this.item.date : "",
            time: this.item.status == 2 ? this.item.hour : "",
            agent_status: this.item.agent_status,
            agent_status_update: false, agent_comment: '', showHTML: false,
            editorConfigEn: {
                placeholder: "Agent Comment",
                language: "en",
            },
            editor: ClassicEditor,
        };
    },
    props: {
        item: {
            required: true,
            type: Object,
            default() {
                return {
                    id: null,
                    user_name: "Undefined",
                    user_mobile: "Undefined",
                    user_email: "Undefined",
                    car_brand: "Undefined",
                    car_model: "Undefined",
                    car_year: "Undefined",
                    service_center: "Undefined",
                    service: "Undefined",
                    km: "Undefined",
                    date: "Undefined",
                    hour: "Undefined",
                    status: null,
                    additional_comments: "Undefined",
                    agent_status: "Undefined",
                    created_at: "Undefined",
                };
            },
        },
    },
    methods: {
        changeAgentStatus() {
            const payload = {
                request_id: this.item.id,
                request_type: "booking",
                status: this.agent_status,
                agent_comment: this.agent_comment
            };
            console.log(this.$store.state.auth.token);
            this.$httpAdmin
                .post(`/requests/change-status`, payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Agent Status",
                        text: "Agent Status is changed successfully!",
                        color: "success",
                    });
                    location.reload();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getAgentStatusColor(status) {
            if (status == "pending") return "dark";
            if (status == "in_progress") return "primary";
            if (status == "done") return "success";
            return "danger";
        },
        getAgentStatusState(status) {
            if (status == "pending") return "Pending";
            if (status == "in_progress") return "In Progress";
            if (status == "done") return "Done";
            return "Unknown";
        },
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to send this notification!",
                color: "primary",
                accept: () => this.changeStatus(),
                type: "confirm",
            });
        },
        getOrderStatusColor(status, agentStatus) {
            if (status == -1) return "danger";
            if (status == 5) return "danger";
            if (status == 0 && agentStatus !== "in_progress") return "warning";
            if (status == 0 && agentStatus === "in_progress") return "primary";
            if (status == 1) return "success";
            if (status == 2) return "dark";
            if (status == 3) return "primary";
            return "Unknown";
        },
        getOrderStatusState(status, agentStatus) {
            if (status == -1) return "Cancelled";
            if (status == 5) return "Not Reachable";
            if (status == 0 && agentStatus !== "in_progress") return "Pending";
            if (status == 0 && agentStatus === "in_progress")
                return "In Progress";
            if (status == 1) return "Accepted";
            if (status == 2) return "Scheduled";
            if (status == 3) return "Completed";
            return "Unknown";
        },
        viewRequests() {
            this.$emit("viewRequests");
        },
        changeStatus() {
            this.$vs.loading();

            let payload;

            if (this.new_status == 2) {
                payload = {
                    status: this.new_status,
                    date: this.date,
                    hour: this.time,
                    agent_comment: this.agent_comment
                };
            } else {
                payload = {
                    status: this.new_status,
                    agent_comment: this.agent_comment
                };
            }

            this.$httpAdmin
                .post(`/booking-requests/${this.item.id}`, payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Booking Status",
                        text: "Booking Status is changed successfully!",
                        color: "success",
                    });
                    this.item.status = this.new_status;
                    this.item.date = this.date;
                    this.item.hour = this.time;
                    this.status = false;
                    this.viewRequests();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    computed: {
        invalidForm() {
            if (this.new_status == 2) {
                if (this.date && this.time) {
                    return false;
                }
                return true;
            }
            return false;
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
/*label {*/
/*  display: block;*/
/*  color: rgba(0, 0, 0, 0.6);*/
/*  font-size: 12px;*/
/*  margin-top: 20px;*/
/*}*/

label {
    margin-left: 15px;
}

select {
    width: 90%;
    margin-left: 15px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.select {
    width: 25%;
    float: right;
    margin-bottom: 30px;
}

.clr {
    clear: both;
}

td,
th {
    font-size: 1rem;
    padding-bottom: 20px;
    color: #00000090;
}

.flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.margin {
    margin-right: 10px;
}

.input {
    margin-top: 0 !important;
    padding: 0 20px 0 20px;
    width: 100%;
}

.optionBtns {
    margin-top: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
        position: relative;
        left: 25px;
    }
}

* {
    margin: 0;
    padding: 0;
}

.card {
    margin-top: 30px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.main-component {
    cursor: pointer;
}

.name {
    font-weight: 900;
}

.header {
    margin: 20px 0px 20px 0px;
    color: grey;
}

.status {
    margin: 0 50px 50px 0;
    float: right;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.active {
    background-color: #28c76f;
    border-radius: 50px;
    color: white;
}

.cancelled {
    background-color: #ff9f43;
    border-radius: 50px;
    color: white;
}

.clr {
    clear: both;
}

.name {
    font-weight: 900;
}

.main-component {
    cursor: pointer;
}

.reason {
    visibility: hidden;
}

.inline {
    display: inline-block;
}

td {
    text-transform: capitalize;
}

.product-order-status {
    padding-right: 10px;
}
</style>
