<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} CAR TYPE</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">


        <!-- English NAME -->
        <vs-input label="English Name" v-model="form.name_en" class="mt-5 w-full" name="name_en" />
        <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>

        <!-- Arabic NAME -->
        <vs-input label="Arabic Name" v-model="form.name_ar" class="mt-5 w-full" name="name_ar" />
        <span class="text-danger text-sm" v-show="errors.has('name_ar')">{{ errors.first('name_ar') }}</span>

        <div class="mt-5 w-full">
          <label>Show on Mobile</label>
          <vs-switch v-model="form.mobile_active"/>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    export default {
        props: {
            isSidebarActive: {
                type: Boolean,
                required: true
            },
            data: {
                type: Object,
                default: () => {},
            }
        },
        data() {
            return {
                form: {
                    name_en: "",
                    name_ar: "",
                    mobile_active: false
                },
                id: null,
                settings: {
                    maxScrollbarLength: 150,
                    wheelSpeed: .80,
                },
            }
        },
        watch: {
            isSidebarActive(val) {
                if(!val) return;
                if(Object.entries(this.data).length === 0) {
                    this.initValues();
                    this.$validator.reset()
                }else {
                    let { id, name_en, name_ar, mobile_active } = JSON.parse(JSON.stringify(this.data));
                    this.id = id;
                    this.form = {
                        name_en,
                        name_ar,
                        mobile_active
                    };
                    this.initValues()
                }
            }
        },
        computed: {
            isSidebarActiveLocal: {
                get() {
                    return this.isSidebarActive
                },
                set(val) {
                    if(!val) {
                        this.$emit('closeSidebar');
                        this.$validator.reset();
                        this.initValues()
                    }
                }
            },
            isFormValid() {
                return !this.errors.any() && this.form.name_en && this.form.name_ar;
            }
        },
        methods: {
            initValues() {
                if(this.data.id) return;
                this.id = null;
                this.form = {
                    name_en: "",
                    name_ar: "",
                    mobile_active: false
                };
                this.id = null;
            },
            submitData() {
                this.$validator.validateAll().then(result => {
                    this.$vs.loading();
                    if (result) {
                        const obj = this.form;

                        const formData = new FormData();

                        formData.append("name_en", obj.name_en);
                        formData.append("name_ar", obj.name_ar);
                        formData.append("mobile_active", Number(obj.mobile_active));

                        if (this.id !== null && this.id >= 0) {
                            this.$httpCars.post("/car-types/" + this.id, formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            }).then(() => {
                                const message = "Car Type has been updated successfully";
                                this.$vs.notify({
                                    title: "Successful operation",
                                    text: message,
                                    color: "success"
                                });
                                this.$emit('onSuccess');
                            }).catch(() => {
                                //console.log({err});

                                this.$vs.loading.close();
                                this.$vs.notify({
                                    title: "Something Error 🙁",
                                    text: "Couldn't add data to the server",
                                    color: 'danger'
                                });
                            }).finally(() => {
                                this.$emit('closeSidebar');
                                this.initValues();
                                this.$vs.loading.close();
                            });
                        } else{
                            this.$httpCars.post("/car-types", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            }).then(() => {
                                const message = "Car Type has been created successfully";
                                this.$vs.notify({
                                    title: "Successful operation",
                                    text: message,
                                    color: "success"
                                });
                                this.$emit('onSuccess');
                            }).catch(() => {
                                //console.log({err});

                                this.$vs.loading.close();
                                this.$vs.notify({
                                    title: "Something Error 🙁",
                                    text: "Couldn't add data to the server",
                                    color: 'danger'
                                });
                            }).finally(() => {
                                this.$emit('closeSidebar');
                                this.initValues();
                                this.$vs.loading.close();
                            });
                        }

                        this.$emit('closeSidebar');
                        this.initValues();
                        this.$vs.loading.close();
                    }
                })
            },
        },
        components: {
            VuePerfectScrollbar,
        }
    }
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;

      .img-upload {
        margin-top: 2rem;

        .con-img-upload {
          padding: 0;
        }

        .con-input-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
</style>
