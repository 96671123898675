<template>
    <div>
        <vs-td class="whitespace-no-wrap td-action">
            <feather-icon
                icon="EyeIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="$emit('onViewClicked')"
            />
            <feather-icon
                v-if="canDelete"
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="popupActivo = true"
            />
        </vs-td>

        <vs-popup
            class="holamundo"
            title="Delete Item"
            :active.sync="popupActivo"
        >
            <div>
                <h3 class="text-center text-danger">
                    You are about to delete this item!!
                </h3>
                <div class="text-center mt-8 sure-message">Are You Sure?</div>

                <div class="float-right d-flex mt-8">
                    <vs-button
                        color="success"
                        class="mr-2"
                        @click="popupActivo = false"
                    >
                        Cancel
                    </vs-button>
                    <vs-button color="danger" class="ml-2" @click="deleteItem">
                        Delete
                    </vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import store2 from "store2";

export default {
    name: "actions-row-2",
    props: {
        permissionName: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            popupActivo: false,
            canDelete: true,
        };
    },
    methods: {
        deleteItem() {
            this.popupActivo = false;
            this.$emit("onDeleteClicked");
        },
    },
    mounted() {
        if (store2.get("admin") === "Super Admin") {
            return;
        }
        if (this.permissionName) {
            const permissions = JSON.parse(store2.get("permissions"));
            this.canDelete = permissions.find(
                (permission) => permission.title === this.permissionName
            );
        }
    },
};
</script>
